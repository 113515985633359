.App {
  text-align: center;
  /* min-width: 480px; */
}
@font-face {
  font-family: "Crimson Text Regular";
  src: local("Crimson Text Regular"),
    url(./assets/fonts/Crimson_Text/CrimsonText-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Josefin Sans Regular";
  src: local("Josefin Sans Regular"),
    url(./assets/fonts/Josefin_Sans/JosefinSans-Regular.ttf) format("truetype");
}

a:link {
  color: hotpink;
  text-decoration: none;
  background-color: transparent;
}
a:visited {
  color: hotpink;
  text-decoration: none;
  background-color: transparent;
}
a:hover,
a:active {
  color: hotpink;
  text-decoration: none;
  background-color: #fff7f8;
}

.App-columns {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.App-pic-rows {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.App-pic {
  border-radius: 50%;
  margin: 10px;
  height: calc(200px + 15vmin);
  box-shadow: 0px 10px 10px #f6e6f1;
}
.App-writeup {
  text-align: left;
  color: #2c292a;
  font-family: "Crimson Text Regular";
  font-size: calc(7px + 1.5vmin);
  margin: 0px calc(5px + 2vw) 0px calc(5px + 2vw);
}
.App-title {
  font-size: calc(10px + 2vmin);
  font-family: "Josefin Sans Regular";
}

.App-copyright {
  font-family: "Josefin Sans Regular";
  font-size: calc(7px + 1vmin);
  text-align: center;
  color: #f6e6f1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
